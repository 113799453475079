@import "_common";

.mv {
    background: -webkit-gradient(linear, 45deg, from(#f0f5fa), to(rgba(174, 201, 228, .8)));
    background: -webkit-linear-gradient(45deg, #f0f5fa, rgba(174, 201, 228, .8));
    background: -moz-linear-gradient(45deg, #f0f5fa, rgba(174, 201, 228, .8));
    background: linear-gradient(45deg, #f0f5fa, rgba(174, 201, 228, .8));
    position: relative;
    height: 43vw;
    margin-bottom: 80px;
    overflow: hidden;

    @media all and ($sm) {
        height: 84vh;
        margin-bottom: 50px;
    }

    &:before,
    &:after {
        content: "";
        display: block;
    }

    &:before {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 20px;
        right: -20px;
        z-index: 2;
        background-color: rgba($color: #fff, $alpha: .5);

        @media all and ($sm) {
            width: 45px;
            height: 45px;
            top: 100px;
            right: -10px;
        }
    }

    &:after {
        background: url(../images/top/mv@2x.png) no-repeat top left;
        background-size: cover;
        width: 60vw;
        height: 35vw;
        position: absolute;
        top: 40px;
        right: 0;
        z-index: 1;

        @media all and ($sm) {
            background: url(../images/top/mv@sp.png) no-repeat top left;
            background-size: cover;
            top: 170px;
            width: calc(100% - 15px);
            height: 100vw;
        }
    }

    .container {
        position: relative;
        height: 100%;

        @media all and ($sm) {
            padding-top: 40px;
            -webkit-box-align: flex-start;
            -ms-flex-align: flex-start;
            -webkit-align-items: flex-start;
            align-items: flex-start;
        }

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            background: -webkit-gradient(linear, 45deg, from(rgba(46, 49, 103, .7)), to(rgba(46, 49, 146, .5)));
            background: -webkit-linear-gradient(45deg, rgba(46, 49, 103, .7), rgba(46, 49, 146, .5));
            background: -moz-linear-gradient(45deg, rgba(46, 49, 103, .7), rgba(46, 49, 146, .5));
            background: linear-gradient(45deg, rgba(46, 49, 103, .7), rgba(46, 49, 146, .5));
            z-index: 1;
        }

        &:before {
            width: 60px;
            height: 60px;
            top: 40px;
            left: -20px;

            @media all and ($sm) {
                width: 70px;
                height: 70px;
                top: -45px;
                left: auto;
                right: 60px;
            }
        }

        &:after {
            width: 80px;
            height: 80px;
            bottom: 30px;
            left: 340px;

            @media all and ($sm) {
                width: 40px;
                height: 40px;
                bottom: 0;
                left: auto;
                right: 20px;
            }
        }
    }

    h1 {
        color: $c_navy;
        font: bold 3.4vw/200% $font_g;
        letter-spacing: 2px;
        position: relative;
        z-index: 2;

        @media all and ($sm) {
            width: 100%;
            text-align: center;
            font: bold 27px/160% $font_g;

            &:after {
                width: 30px;
                height: 30px;
                right: 50px;
                top: -35px;
            }
        }

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            z-index: 2;
            background-color: rgba($color: #aec9e4, $alpha: .5);
        }

        &:before {
            width: 50px;
            height: 50px;
            top: calc(50% - 25px);
            left: -100px;

            @media all and ($sm) {
                width: 140px;
                height: 140px;
                left: -40px;
                top: 130px;
            }
        }
    }
}

.main_content {

    @media all and ($sm) {
        padding: 0 15px;
    }

    p {
        text-align: center;
        margin-bottom: 1rem;
        font: 100 18px/190% $font_g;

        @media all and ($sm) {
            font: 100 15px/170% $font_g;
            text-align: left;

            br {
                display: none;
            }
        }
    }
}

.section_h2 {
    text-align: center;
    margin-top: 100px;
    font-size: 35px;
    font-weight: bold;
    position: relative;
    color: $c_navy;

    @media all and ($sm) {
        font-size: 27px;
        margin-bottom: 15px;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        display: block;
        width: 15px;
        height: 15px;
    }

    &:before {
        background: -webkit-gradient(linear, 45deg, from(#2e3167), to(rgba(46, 49, 146, .8)));
        background: -webkit-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, .8));
        background: -moz-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, .8));
        background: linear-gradient(45deg, #2e3167, rgba(46, 49, 146, .8));
        left: calc(50% - 10px);
        top: -20px;
        z-index: 2;
    }

    &:after {
        background-color: rgba($color: #adadad, $alpha: .5);
        left: calc(50% - 5px);
        top: -15px;
        z-index: 1;
    }
}

.area_service {
    .block_service {
        margin-bottom: 30px;
        background: url(../images/top/bg_r.png) repeat-y right top;
        background-size: 60vw;
        padding: 40px 0;

        @media all and ($sm) {
            background-size: calc(100% - 15px);
        }

        .container {
            width: 900px;
        }

        &:nth-child(even) {
            background: url(../images/top/bg_l.png) repeat-y left top;
            background-size: 60vw;

            @media all and ($sm) {
                background-size: calc(100% - 15px);

                .box_fadein {
                    text-align: right;
                }
            }

            .container {
                -webkit-box-orient: horizontal;
                -webkit-box-direction: reverse;
                -ms-flex-direction: row-reverse;
                flex-direction: row-reverse;
            }

            .content {
                padding: 0 40px 0 0;

                @media all and ($sm) {
                    padding: 0 40px 0 20px;
                }
            }
        }
    }

    .box_fadein {
        width: 480px;

        @media all and ($sm) {
            width: 100%;
            margin-bottom: 25px;

            img {
                width: calc(100% - 25px);
            }
        }
    }

    .content {
        padding-left: 40px;
        width: calc(100% - 480px);

        @media all and ($sm) {
            width: 100%;
            padding: 0 20px 0 40px;
        }
    }

    h3 {
        font-size: 26px;
        font-weight: 700;
        color: $c_navy;
        margin-bottom: 15px;

        @media all and ($sm) {
            font-size: 20px;
            margin-bottom: 5px;
        }
    }

    p {
        text-align: left;
        margin-bottom: 30px;
    }

    .btn_more {
        text-align: right;

        a {
            color: $c_navy;
            text-decoration: none;
            display: inline-block;
            position: relative;

            &:before,
            &:after {
                content: "";
                display: inline-block;
                height: 2px;
                position: absolute;
                bottom: -5px;
                left: -5px;
                transition: all .3s;
            }

            &:before {
                width: calc(100% + 10px);
                background-color: #fff;
            }

            &:after {
                width: 15px;
                background-color: $c_navy;
            }

            &:hover {
                opacity: 1;

                &:after {
                    width: calc(100% + 10px);
                }
            }
        }
    }
}

.area_topblog {
    margin: 30px auto 100px;
    position: relative;

    @media all and ($sm) {
        padding: 0 15px 30px;
    }

    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50px;
        left: 0;
        background: -webkit-gradient(linear, left top, right top, from(rgba(174, 201, 228, .5)), to(rgba(235, 245, 250, 1)));
        background: -webkit-linear-gradient(left, rgba(174, 201, 228, .5), rgba(235, 245, 250, 1));
        background: -moz-linear-gradient(left, rgba(174, 201, 228, .5), rgba(235, 245, 250, 1));
        background: linear-gradient(to right, rgba(174, 201, 228, .5), rgba(235, 245, 250, 1));

        @media all and ($sm) {
            top: 25px;
        }
    }

    .container{
        gap: 40px;
    }

    .block {
        width: calc( 100% / 3 - 27px);
        position: relative;
        z-index: 3;
        background-color: #fff;

        &:last-child {
            margin: 0;
        }

        @media all and ($sm) {
            width: 100%;
            margin: 0 0 35px;
        }
    }

    .date {
        padding: 30px 30px 8px;
        font-size: 12px;
        color: #666;
    }

    h3 {
        padding: 0 30px 18px;

        a {
            text-decoration: none;
            color: #333;
        }
    }

    .category {
        padding: 0 30px 30px;
        list-style-type: none;

        li {
            font-size: 14px;
            background-color: #cedfef;
            color: $c_navy;
            font-weight: bold;
            line-height: 100%;
            padding: 10px;
            margin-right: 5px;

            &:last-child {
                margin: 0;
            }
        }
    }

    .btn_more {
        position: absolute;
        right: -18px;
        bottom: -18px;

        @media all and ($sm) {
            right: 15px;
        }

        a {
            background: -webkit-gradient(linear, 45deg, from(#2e3167), to(rgba(46, 49, 146, .8)));
            background: -webkit-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, .8));
            background: -moz-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, .8));
            background: linear-gradient(45deg, #2e3167, rgba(46, 49, 146, .8));
            display: block;
            width: 50px;
            height: 50px;
            line-height: 60px;
            text-align: center;

            @media all and ($sm) {
                width: 40px;
                height: 40px;
                line-height: 50px;
            }

            svg {
                fill: #fff;

                @media all and ($sm) {
                    width: 17px;
                }
            }
        }
    }
}