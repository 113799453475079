@charset "UTF-8";
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

a:focus {
  outline: none;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bold;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit],
select {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

* {
  box-sizing: border-box;
}

body,
p,
ul,
li,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(14, 24, 14);
  font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 200%;
  margin: 0;
  font-feature-settings: "palt";
}

a {
  color: rgb(14, 24, 14);
  text-decoration: underline;
  transition: all 0.2s;
}
a:hover {
  text-decoration: none;
  color: rgb(14, 24, 14);
  opacity: 0.7;
}

.t_right {
  text-align: right !important;
}

.t_center {
  text-align: center !important;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex.wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex.center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.container {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
}

.box-fadein img,
.box-fadein iframe {
  opacity: 0;
  -webkit-transition: opacity 1.2s;
  transition: opacity 1.2s;
}
.box-fadein img.lazyloaded,
.box-fadein iframe.lazyloaded {
  opacity: 1;
}

@media all and (max-width: 599px) {
  .view_pc {
    display: none;
  }
}

.view_sp {
  display: none;
}
@media all and (max-width: 599px) {
  .view_sp {
    display: block;
  }
}

.c_navy {
  color: #2e3192;
}

#loading {
  width: 100vw;
  height: 100vh;
  transition: all 0.5s;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-justify-content: center;
  justify-content: center;
}
#loading.loaded {
  opacity: 0;
  visibility: hidden;
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  box-shadow: 9984px 0 0 0 #2e3192, 9999px 0 0 0 #2e3192, 10014px 0 0 0 #2e3192;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 #2e3192, 9999px 0 0 0 #2e3192, 10014px 0 0 0 #2e3192;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #2e3192, 9999px 0 0 0 #2e3192, 10014px 0 0 0 #2e3192;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #2e3192, 9999px 0 0 0 #2e3192, 10014px 0 0 0 #2e3192;
  }
  50% {
    box-shadow: 9984px 0 0 0 #2e3192, 9999px -10px 0 0 #2e3192, 10014px 0 0 0 #2e3192;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #2e3192, 9999px 0 0 0 #2e3192, 10014px 0 0 0 #2e3192;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #2e3192, 9999px 0 0 0 #2e3192, 10014px -10px 0 0 #2e3192;
  }
  100% {
    box-shadow: 9984px 0 0 0 #2e3192, 9999px 0 0 0 #2e3192, 10014px 0 0 0 #2e3192;
  }
}
.inview .op1,
.inview .op2,
.inview .op3,
.inview .op4,
.inview .op5,
.inview .op6,
.inview .op7,
.inview .op8,
.inview .op9 {
  opacity: 0;
}
.inview.on .op1 {
  animation: my_opacity 0.8s ease-in-out 0.8s forwards;
}
.inview.on .op2 {
  animation: my_opacity 0.8s ease-in-out 1.2s forwards;
}
.inview.on .op3 {
  animation: my_opacity 0.8s ease-in-out 1.6s forwards;
}
.inview.on .op4 {
  animation: my_opacity 0.8s ease-in-out 2s forwards;
}
.inview.on .op5 {
  animation: my_opacity 0.8s ease-in-out 2.4s forwards;
}
.inview.on .op6 {
  animation: my_opacity 0.8s ease-in-out 2.8s forwards;
}
.inview.on .op7 {
  animation: my_opacity 0.8s ease-in-out 3.2s forwards;
}
.inview.on .op8 {
  animation: my_opacity 0.8s ease-in-out 3.6s forwards;
}
.inview.on .op9 {
  animation: my_opacity 0.8s ease-in-out 4s forwards;
}

@keyframes my_opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* ============================================
  header
============================================ */
.header {
  background-color: #fff;
}
@media all and (max-width: 599px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 100;
  }
  .header .logo {
    width: 170px;
    padding-left: 15px;
  }
  .header .logo a {
    display: block;
  }
}
.header .container {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 10px 0;
}
@media all and (max-width: 599px) {
  .header .container {
    padding: 0;
  }
}
.header .main-nav {
  background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
  background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, 0.8));
}
@media all and (max-width: 599px) {
  .header .main-nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    padding: 70px 15px 0;
    z-index: 10;
    display: none;
  }
}
.header .main-nav .main-nav__list {
  padding: 0;
  -webkit-justify-content: center;
  justify-content: center;
}
@media all and (max-width: 599px) {
  .header .main-nav .main-nav__list {
    padding-bottom: 10px;
  }
}
.header .main-nav .main-nav__list li {
  list-style-type: none;
  margin: 0 40px;
  font-size: 15px;
}
@media all and (max-width: 599px) {
  .header .main-nav .main-nav__list li {
    line-height: 100%;
    margin: 0 0 25px;
    font-size: 15px;
    width: 100%;
  }
}
.header .main-nav .main-nav__list li.menu-item-has-children {
  position: relative;
}
.header .main-nav .main-nav__list li.menu-item-has-children > a {
  border: none;
  font-weight: bold;
  background: none;
}
.header .main-nav .main-nav__list li.menu-item-has-children:hover .sub-menu {
  display: block;
  position: absolute;
  left: 0;
  z-index: 10;
  width: 180px;
  border-top: solid 1px #fff;
}
@media all and (max-width: 599px) {
  .header .main-nav .main-nav__list li.menu-item-has-children:hover .sub-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: static;
    width: 100%;
    border: none;
  }
}
.header .main-nav .main-nav__list li a {
  color: #fff;
  text-decoration: none;
  padding: 20px 0;
  display: block;
}
.header .main-nav .main-nav__list li a:hover {
  cursor: pointer;
}
@media all and (max-width: 599px) {
  .header .main-nav .main-nav__list li a {
    border-bottom: solid 1px #fff;
    display: block;
    padding: 0 0 10px;
    background: url(../images/arrow_nav.svg) no-repeat right top 3px;
    background-size: 5px;
  }
}
.header .main-nav .main-nav__list li .sub-menu {
  display: none;
  margin: 0;
  background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
  background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, 0.8));
  padding: 0;
}
@media all and (max-width: 599px) {
  .header .main-nav .main-nav__list li .sub-menu {
    padding: 8px 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    background: none;
  }
}
.header .main-nav .main-nav__list li .sub-menu li {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  margin: 0;
  border-bottom: solid 1px #fff;
}
@media all and (max-width: 599px) {
  .header .main-nav .main-nav__list li .sub-menu li {
    width: calc(50% - 6px);
    margin: 0 0 15px;
  }
}
.header .main-nav .main-nav__list li .sub-menu li a {
  display: block;
  padding: 20px;
}
@media all and (max-width: 599px) {
  .header .main-nav .main-nav__list li .sub-menu li a {
    padding: 0 0 8px;
  }
}
.header .main-nav .btn_contact {
  padding: 0;
  margin-bottom: 6px;
}
.header .main-nav .btn_contact a {
  width: 100%;
}
.header .main-nav .btn_contact svg {
  margin-right: 5px;
  width: 23px;
}
.header .main-nav .btn_contact span {
  display: block;
  font-weight: bold;
}
.header .main-nav .btns {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
}
.header .main-nav .btns .btn {
  width: calc(50% - 3px);
}
.header .main-nav .btns .btn:last-child {
  margin: 0;
}
.header .main-nav .btns .btn a {
  -webkit-justify-content: center;
  justify-content: center;
  font: 700 20px/120% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  border: solid 1px #fff;
  height: 60px;
  text-decoration: none;
  color: #fff;
  width: 100%;
}
.header .main-nav .btns .btn a svg {
  margin-right: 5px;
}
.header .main-nav .btns .btn.tel a {
  line-height: 100%;
  padding-top: 2px;
  background-color: #171a70;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header .main-nav .btns .btn.tel a > span {
  -webkit-justify-content: center;
  justify-content: center;
}
.header .main-nav .btns .btn.tel svg {
  width: 10px;
  height: 25px;
  margin: -2px 4px 0 0;
  fill: #fff;
}
.header .main-nav .btns .btn.tel .number {
  font: 500 18px/100% roboto, sans-serif;
  display: block;
  color: #fff;
}
.header .main-nav .btns .btn.tel .time {
  display: block;
  text-align: center;
  font-size: 13px;
  height: 20px;
}
.header .main-nav .btns .btn.line a {
  background-color: #171a70;
  font-size: 15px;
}
.header .main-nav .btns .btn.line svg {
  width: 30px;
}
.header .main-nav .btns .btn.line .cls-1 {
  fill: #00b900;
}
.header .main-nav .btns .btn.line .cls-2 {
  fill: #fff;
}
.header .tel {
  text-align: right;
  margin-right: 20px;
}
@media all and (max-width: 599px) {
  .header .tel {
    margin: 0;
  }
}
.header .tel .time {
  font-size: 12px;
}
@media all and (max-width: 599px) {
  .header .tel .time {
    display: none;
  }
}
.header .tel a {
  text-decoration: none;
}
@media all and (max-width: 599px) {
  .header .tel a {
    display: block;
    width: 60px;
    height: 60px;
    line-height: 80px;
    text-align: center;
    background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
    background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
    background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
    background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, 0.8));
  }
}
.header .tel .number {
  font: 500 26px/100% roboto, sans-serif;
  color: #2e3192;
}
@media all and (max-width: 599px) {
  .header .tel .number {
    display: none;
  }
}
.header .tel svg {
  fill: #2e3192;
  width: 12px;
  height: 20px;
  margin-right: 6px;
}
@media all and (max-width: 599px) {
  .header .tel svg {
    width: 21px;
    height: 32px;
    fill: #fff;
    margin: 0;
  }
}
@media all and (max-width: 599px) {
  .header .btn_contact {
    padding-right: 60px;
  }
}
.header .btn_contact a {
  color: #fff;
  text-decoration: none;
  padding: 20px;
  background: -webkit-gradient(linear, 45deg, from(#ff581b), to(rgba(255, 172, 51, 0.8)));
  background: -webkit-linear-gradient(45deg, #ff581b, rgba(255, 172, 51, 0.8));
  background: -moz-linear-gradient(45deg, #ff581b, rgba(255, 172, 51, 0.8));
  background: linear-gradient(45deg, #ff581b, rgba(255, 172, 51, 0.8));
}
@media all and (max-width: 599px) {
  .header .btn_contact a {
    padding: 0;
    width: 60px;
    height: 60px;
    -webkit-justify-content: center;
    justify-content: center;
  }
}
.header .btn_contact span {
  font-weight: bold;
}
@media all and (max-width: 599px) {
  .header .btn_contact span {
    display: none;
  }
}
.header .btn_contact svg {
  width: 21px;
  margin-right: 10px;
}
@media all and (max-width: 599px) {
  .header .btn_contact svg {
    width: 30px;
    height: 23px;
    margin: 0;
  }
}
.header .btn_contact .cls-1 {
  fill: #fff;
}

#menuButton {
  display: none;
  width: 42px;
  height: 42px;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 11;
}
@media all and (max-width: 599px) {
  #menuButton {
    display: block;
  }
}
#menuButton span {
  display: block;
  background: #2e3192;
  width: 24px;
  height: 2px;
  position: absolute;
  left: 9px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}
#menuButton span:first-child {
  top: 12px;
}
#menuButton span:nth-child(2) {
  margin-top: -1px;
  top: 50%;
}
#menuButton span:last-child {
  bottom: 12px;
}
#menuButton.active span {
  background-color: #fff;
}
#menuButton.active span:first-child {
  -webkit-transform: translateY(8px) rotate(45deg);
  -moz-transform: translateY(8px) rotate(45deg);
  -ms-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
}
#menuButton.active span:nth-child(2) {
  opacity: 0;
}
#menuButton.active span:last-child {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -moz-transform: translateY(-8px) rotate(-45deg);
  -ms-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
}

.breadcrumb {
  text-align: right;
  padding: 10px 0;
}
.breadcrumb .breadcrumb__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.breadcrumb .breadcrumb__list li:after {
  content: ">";
  margin: 0 10px 0 13px;
}
.breadcrumb .breadcrumb__list li:last-child:after {
  content: "";
  margin: 0;
}

@media all and (max-width: 599px) {
  .main {
    padding-top: 60px;
  }
}

/* ============================================
  area_contact
============================================ */
.area_contact {
  background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
  background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, 0.8));
}
.area_contact .container {
  width: 950px;
  background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
  background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, 0.8));
  padding: 56px 0 50px;
  position: relative;
}
.area_contact .container:before, .area_contact .container:after {
  content: "";
  position: absolute;
  display: block;
}
.area_contact .container:before {
  width: 70px;
  height: 70px;
  top: -14px;
  left: -24px;
  background-color: rgba(174, 201, 228, 0.7);
}
@media all and (max-width: 599px) {
  .area_contact .container:before {
    width: 50px;
    height: 50px;
    left: 10px;
  }
}
.area_contact .container:after {
  width: 35px;
  height: 35px;
  top: 31px;
  left: 20px;
  background-color: rgba(255, 255, 255, 0.5);
}
@media all and (max-width: 599px) {
  .area_contact .container:after {
    width: 25px;
    height: 25px;
    top: 20px;
    left: 43px;
  }
}
.area_contact h2 {
  text-align: center;
  font: 700 34px/130% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  margin-bottom: 30px;
  color: #fff;
}
@media all and (max-width: 599px) {
  .area_contact h2 {
    font-size: 30px;
    margin-bottom: 10px;
  }
}
.area_contact p {
  text-align: center;
  font: 400 18px/180% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  margin-bottom: 30px;
  color: #fff;
}
@media all and (max-width: 599px) {
  .area_contact p {
    font-size: 15px;
  }
}
.area_contact .btns {
  -webkit-justify-content: center;
  justify-content: center;
  width: 830px;
  margin: 0 auto;
}
@media all and (max-width: 599px) {
  .area_contact .btns {
    width: 100%;
    padding: 0 15px;
  }
}
.area_contact .btn {
  width: calc(33.3333333333% - 7px);
  margin-right: 10px;
}
.area_contact .btn:last-child {
  margin: 0;
}
.area_contact .btn a {
  -webkit-justify-content: center;
  justify-content: center;
  font: 700 20px/120% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  border: solid 1px #fff;
  height: 100px;
  text-decoration: none;
  color: #fff;
}
@media all and (max-width: 599px) {
  .area_contact .btn a {
    height: 70px;
  }
}
.area_contact .btn a svg {
  margin-right: 15px;
}
@media all and (max-width: 599px) {
  .area_contact .btn.mail {
    width: 100%;
    margin: 0 0 6px;
  }
}
.area_contact .btn.mail a {
  background: -webkit-gradient(linear, left top, right top, from(#ff581b), to(rgba(255, 172, 51, 0.8)));
  background: -webkit-linear-gradient(left, #ff581b, rgba(255, 172, 51, 0.8));
  background: -moz-linear-gradient(left, #ff581b, rgba(255, 172, 51, 0.8));
  background: linear-gradient(to right, #ff581b, rgba(255, 172, 51, 0.8));
}
@media all and (max-width: 599px) {
  .area_contact .btn.mail a {
    border: none;
  }
  .area_contact .btn.mail a br {
    display: none;
  }
  .area_contact .btn.mail a svg {
    width: 23px;
    margin-right: 8px;
  }
}
@media all and (max-width: 320px) {
  .area_contact .btn.mail a {
    font-size: 18px;
  }
}
.area_contact .btn.mail .cls-1 {
  fill: #fff;
}
@media all and (max-width: 599px) {
  .area_contact .btn.tel {
    width: calc(50% - 3px);
    margin-right: 6px;
  }
}
.area_contact .btn.tel a {
  line-height: 100%;
  padding-top: 2px;
  background-color: #171a70;
}
.area_contact .btn.tel a > span {
  -webkit-justify-content: center;
  justify-content: center;
}
.area_contact .btn.tel svg {
  width: 16px;
  height: 25px;
  margin: 2px 5px 0 0;
  fill: #fff;
}
@media all and (max-width: 599px) {
  .area_contact .btn.tel svg {
    width: 10px;
    margin: -2px 4px 0 0;
  }
}
@media all and (max-width: 320px) {
  .area_contact .btn.tel svg {
    width: 9px;
    height: 18px;
  }
}
.area_contact .btn.tel .number {
  font: 500 30px/100% roboto, sans-serif;
  margin-bottom: 6px;
}
@media all and (max-width: 599px) {
  .area_contact .btn.tel .number {
    font-size: 18px;
    margin: 0;
  }
}
@media all and (max-width: 320px) {
  .area_contact .btn.tel .number {
    font-size: 15px;
  }
}
.area_contact .btn.tel .time {
  display: block;
  text-align: center;
  font-size: 13px;
  height: 20px;
}
@media all and (max-width: 320px) {
  .area_contact .btn.tel .time {
    font-size: 11px;
  }
}
@media all and (max-width: 599px) {
  .area_contact .btn.line {
    width: calc(50% - 3px);
  }
}
.area_contact .btn.line a {
  background-color: #171a70;
}
@media all and (max-width: 599px) {
  .area_contact .btn.line a {
    font-size: 15px;
  }
  .area_contact .btn.line a svg {
    width: 30px;
  }
}
@media all and (max-width: 320px) {
  .area_contact .btn.line a {
    font-size: 13px;
  }
  .area_contact .btn.line a svg {
    margin-right: 8px;
  }
}
.area_contact .btn.line .cls-1 {
  fill: #00b900;
}
.area_contact .btn.line .cls-2 {
  fill: #fff;
}

/* ============================================
  footer
============================================ */
.footer {
  position: relative;
  background-color: #fff;
}
.footer:after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  background-color: #f0f5fa;
  width: 50%;
  top: 0;
  left: 50%;
  z-index: 5;
}
@media all and (max-width: 599px) {
  .footer:after {
    display: none;
  }
}
.footer .foot_logo {
  margin-bottom: 26px;
}
.footer address {
  font-style: normal;
  background: url(../images/i_company.svg) no-repeat left top 7px;
  background-size: 14px;
  padding-left: 20px;
}
.footer .tel {
  background: url(../images/i_tel.svg) no-repeat left top 7px;
  background-size: 10px;
  padding-left: 20px;
}
.footer .tel a {
  text-decoration: none;
}
.footer .foot_inner {
  width: 950px;
  position: relative;
  z-index: 10;
  background-color: #f0f5fa;
}
@media all and (max-width: 599px) {
  .footer .foot_inner {
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
    background-color: #fff;
  }
}
.footer .left {
  padding: 50px 40px 50px 0;
  background-color: #fff;
}
@media all and (max-width: 599px) {
  .footer .left {
    padding: 40px 15px 30px;
  }
}
.footer .right {
  padding: 50px 0 50px 50px;
}
@media all and (max-width: 599px) {
  .footer .right {
    display: none;
  }
}
.footer .right > div {
  padding-right: 35px;
}
.footer .right a {
  color: #2e3192;
  background: url(../images/arrow.svg) no-repeat center left;
  background-size: 8px;
  text-decoration: none;
  padding-left: 14px;
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
}
.footer .right ul {
  list-style-type: none;
  padding: 0;
}
.footer .right .ttl {
  color: #2e3192;
  font-size: 15px;
  white-space: nowrap;
}
.footer .right .has_sub .ttl {
  font-weight: bold;
}
.footer .right .has_sub a {
  color: #000;
  font-weight: normal;
}

.btn__pagetop {
  position: absolute;
  top: -25px;
  right: -25px;
}
@media all and (max-width: 599px) {
  .btn__pagetop {
    top: -20px;
    right: 15px;
  }
}
.btn__pagetop svg {
  fill: #2e3192;
}
@media all and (max-width: 599px) {
  .btn__pagetop svg {
    width: 16px;
  }
}
.btn__pagetop a {
  background-color: rgba(198, 217, 236, 0.85);
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  padding-top: 14px;
}
@media all and (max-width: 599px) {
  .btn__pagetop a {
    width: 40px;
    height: 40px;
    padding-top: 9px;
  }
}

.copyright {
  text-align: center;
  background-color: #2e3167;
  color: #fff;
  margin: 0;
  padding: 12px 0;
  position: relative;
  z-index: 10;
}
@media all and (max-width: 599px) {
  .copyright {
    font-size: 14px;
  }
}

.mv {
  background: -webkit-gradient(linear, 45deg, from(#f0f5fa), to(rgba(174, 201, 228, 0.8)));
  background: -webkit-linear-gradient(45deg, #f0f5fa, rgba(174, 201, 228, 0.8));
  background: -moz-linear-gradient(45deg, #f0f5fa, rgba(174, 201, 228, 0.8));
  background: linear-gradient(45deg, #f0f5fa, rgba(174, 201, 228, 0.8));
  position: relative;
  height: 43vw;
  margin-bottom: 80px;
  overflow: hidden;
}
@media all and (max-width: 599px) {
  .mv {
    height: 84vh;
    margin-bottom: 50px;
  }
}
.mv:before, .mv:after {
  content: "";
  display: block;
}
.mv:before {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 20px;
  right: -20px;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5);
}
@media all and (max-width: 599px) {
  .mv:before {
    width: 45px;
    height: 45px;
    top: 100px;
    right: -10px;
  }
}
.mv:after {
  background: url(../images/top/mv@2x.png) no-repeat top left;
  background-size: cover;
  width: 60vw;
  height: 35vw;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1;
}
@media all and (max-width: 599px) {
  .mv:after {
    background: url(../images/top/mv@sp.png) no-repeat top left;
    background-size: cover;
    top: 170px;
    width: calc(100% - 15px);
    height: 100vw;
  }
}
.mv .container {
  position: relative;
  height: 100%;
}
@media all and (max-width: 599px) {
  .mv .container {
    padding-top: 40px;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
}
.mv .container:before, .mv .container:after {
  content: "";
  display: block;
  position: absolute;
  background: -webkit-gradient(linear, 45deg, from(rgba(46, 49, 103, 0.7)), to(rgba(46, 49, 146, 0.5)));
  background: -webkit-linear-gradient(45deg, rgba(46, 49, 103, 0.7), rgba(46, 49, 146, 0.5));
  background: -moz-linear-gradient(45deg, rgba(46, 49, 103, 0.7), rgba(46, 49, 146, 0.5));
  background: linear-gradient(45deg, rgba(46, 49, 103, 0.7), rgba(46, 49, 146, 0.5));
  z-index: 1;
}
.mv .container:before {
  width: 60px;
  height: 60px;
  top: 40px;
  left: -20px;
}
@media all and (max-width: 599px) {
  .mv .container:before {
    width: 70px;
    height: 70px;
    top: -45px;
    left: auto;
    right: 60px;
  }
}
.mv .container:after {
  width: 80px;
  height: 80px;
  bottom: 30px;
  left: 340px;
}
@media all and (max-width: 599px) {
  .mv .container:after {
    width: 40px;
    height: 40px;
    bottom: 0;
    left: auto;
    right: 20px;
  }
}
.mv h1 {
  color: #2e3192;
  font: bold 3.4vw/200% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  letter-spacing: 2px;
  position: relative;
  z-index: 2;
}
@media all and (max-width: 599px) {
  .mv h1 {
    width: 100%;
    text-align: center;
    font: bold 27px/160% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  }
  .mv h1:after {
    width: 30px;
    height: 30px;
    right: 50px;
    top: -35px;
  }
}
.mv h1:before, .mv h1:after {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  background-color: rgba(174, 201, 228, 0.5);
}
.mv h1:before {
  width: 50px;
  height: 50px;
  top: calc(50% - 25px);
  left: -100px;
}
@media all and (max-width: 599px) {
  .mv h1:before {
    width: 140px;
    height: 140px;
    left: -40px;
    top: 130px;
  }
}

@media all and (max-width: 599px) {
  .main_content {
    padding: 0 15px;
  }
}
.main_content p {
  text-align: center;
  margin-bottom: 1rem;
  font: 100 18px/190% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
}
@media all and (max-width: 599px) {
  .main_content p {
    font: 100 15px/170% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
    text-align: left;
  }
  .main_content p br {
    display: none;
  }
}

.section_h2 {
  text-align: center;
  margin-top: 100px;
  font-size: 35px;
  font-weight: bold;
  position: relative;
  color: #2e3192;
}
@media all and (max-width: 599px) {
  .section_h2 {
    font-size: 27px;
    margin-bottom: 15px;
  }
}
.section_h2:before, .section_h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
}
.section_h2:before {
  background: -webkit-gradient(linear, 45deg, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
  background: -webkit-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, 0.8));
  background: -moz-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, 0.8));
  background: linear-gradient(45deg, #2e3167, rgba(46, 49, 146, 0.8));
  left: calc(50% - 10px);
  top: -20px;
  z-index: 2;
}
.section_h2:after {
  background-color: rgba(173, 173, 173, 0.5);
  left: calc(50% - 5px);
  top: -15px;
  z-index: 1;
}

.area_service .block_service {
  margin-bottom: 30px;
  background: url(../images/top/bg_r.png) repeat-y right top;
  background-size: 60vw;
  padding: 40px 0;
}
@media all and (max-width: 599px) {
  .area_service .block_service {
    background-size: calc(100% - 15px);
  }
}
.area_service .block_service .container {
  width: 900px;
}
.area_service .block_service:nth-child(even) {
  background: url(../images/top/bg_l.png) repeat-y left top;
  background-size: 60vw;
}
@media all and (max-width: 599px) {
  .area_service .block_service:nth-child(even) {
    background-size: calc(100% - 15px);
  }
  .area_service .block_service:nth-child(even) .box_fadein {
    text-align: right;
  }
}
.area_service .block_service:nth-child(even) .container {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.area_service .block_service:nth-child(even) .content {
  padding: 0 40px 0 0;
}
@media all and (max-width: 599px) {
  .area_service .block_service:nth-child(even) .content {
    padding: 0 40px 0 20px;
  }
}
.area_service .box_fadein {
  width: 480px;
}
@media all and (max-width: 599px) {
  .area_service .box_fadein {
    width: 100%;
    margin-bottom: 25px;
  }
  .area_service .box_fadein img {
    width: calc(100% - 25px);
  }
}
.area_service .content {
  padding-left: 40px;
  width: calc(100% - 480px);
}
@media all and (max-width: 599px) {
  .area_service .content {
    width: 100%;
    padding: 0 20px 0 40px;
  }
}
.area_service h3 {
  font-size: 26px;
  font-weight: 700;
  color: #2e3192;
  margin-bottom: 15px;
}
@media all and (max-width: 599px) {
  .area_service h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
.area_service p {
  text-align: left;
  margin-bottom: 30px;
}
.area_service .btn_more {
  text-align: right;
}
.area_service .btn_more a {
  color: #2e3192;
  text-decoration: none;
  display: inline-block;
  position: relative;
}
.area_service .btn_more a:before, .area_service .btn_more a:after {
  content: "";
  display: inline-block;
  height: 2px;
  position: absolute;
  bottom: -5px;
  left: -5px;
  transition: all 0.3s;
}
.area_service .btn_more a:before {
  width: calc(100% + 10px);
  background-color: #fff;
}
.area_service .btn_more a:after {
  width: 15px;
  background-color: #2e3192;
}
.area_service .btn_more a:hover {
  opacity: 1;
}
.area_service .btn_more a:hover:after {
  width: calc(100% + 10px);
}

.area_topblog {
  margin: 30px auto 100px;
  position: relative;
}
@media all and (max-width: 599px) {
  .area_topblog {
    padding: 0 15px 30px;
  }
}
.area_topblog:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  background: -webkit-gradient(linear, left top, right top, from(rgba(174, 201, 228, 0.5)), to(rgb(235, 245, 250)));
  background: -webkit-linear-gradient(left, rgba(174, 201, 228, 0.5), rgb(235, 245, 250));
  background: -moz-linear-gradient(left, rgba(174, 201, 228, 0.5), rgb(235, 245, 250));
  background: linear-gradient(to right, rgba(174, 201, 228, 0.5), rgb(235, 245, 250));
}
@media all and (max-width: 599px) {
  .area_topblog:before {
    top: 25px;
  }
}
.area_topblog .container {
  gap: 40px;
}
.area_topblog .block {
  width: calc(33.3333333333% - 27px);
  position: relative;
  z-index: 3;
  background-color: #fff;
}
.area_topblog .block:last-child {
  margin: 0;
}
@media all and (max-width: 599px) {
  .area_topblog .block {
    width: 100%;
    margin: 0 0 35px;
  }
}
.area_topblog .date {
  padding: 30px 30px 8px;
  font-size: 12px;
  color: #666;
}
.area_topblog h3 {
  padding: 0 30px 18px;
}
.area_topblog h3 a {
  text-decoration: none;
  color: #333;
}
.area_topblog .category {
  padding: 0 30px 30px;
  list-style-type: none;
}
.area_topblog .category li {
  font-size: 14px;
  background-color: #cedfef;
  color: #2e3192;
  font-weight: bold;
  line-height: 100%;
  padding: 10px;
  margin-right: 5px;
}
.area_topblog .category li:last-child {
  margin: 0;
}
.area_topblog .btn_more {
  position: absolute;
  right: -18px;
  bottom: -18px;
}
@media all and (max-width: 599px) {
  .area_topblog .btn_more {
    right: 15px;
  }
}
.area_topblog .btn_more a {
  background: -webkit-gradient(linear, 45deg, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
  background: -webkit-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, 0.8));
  background: -moz-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, 0.8));
  background: linear-gradient(45deg, #2e3167, rgba(46, 49, 146, 0.8));
  display: block;
  width: 50px;
  height: 50px;
  line-height: 60px;
  text-align: center;
}
@media all and (max-width: 599px) {
  .area_topblog .btn_more a {
    width: 40px;
    height: 40px;
    line-height: 50px;
  }
}
.area_topblog .btn_more a svg {
  fill: #fff;
}
@media all and (max-width: 599px) {
  .area_topblog .btn_more a svg {
    width: 17px;
  }
}